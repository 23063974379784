<template>
   <div class="homepage d-flex flex-column vh-100">
      <!-- navbar -->
      <div class="homepage-navbar bg-light shadow mb-auto p-3 bg-primary">
         <div class="d-flex align-items-center">
            <a href="#" class="link-dark text-truncate d-flex align-items-center gap-2" data-bs-toggle="offcanvas"
               data-bs-target="#location" aria-controls="location">
               <img class="lgog_img" src="../assets/logo.png"/>
               <span>
                  <h6 class="fw-bold text-white mb-0">Healthline Distributers</h6>
                  <p class="text-white text-truncate d-inline-block mb-0 w-75 align-bottom phonenumber"> <i class="icofont-ui-call  text-white"></i> 82087 03010 </p>
               </span>
            </a>
            <div class="d-flex align-items-center gap-2 ms-auto">
               <router-link :to="(isVerified)?'/profile':'/'" class="link-dark">
                  <div class="bg-dark bg-opacity-75 rounded-circle user-icon"><i
                        class="bi bi-person d-flex m-0 h4 text-white"></i></div>
               </router-link>
               <!-- <a class="toggle" >
                  <b class="bg-dark bg-opacity-75 rounded-circle user-icon">
                     <i class="bi bi-list d-flex m-0 h4 text-white"></i>
                  </b>
               </a> -->
            </div>
         </div>
         <div class="pt-3">
            <!-- search -->
            <a href="#"  >
               <div class="input-group bg-white rounded-3 shadow-sm py-1 col-12">
                   
                  <Autocomplete :suggestions="product_names_for_search" class=" col-10" @update:selected="handleSelection" ></Autocomplete>
                  <!-- <input type="text"  v-on:keyup.enter="search_for"  class="form-control bg-transparent border-0 rounded-0 px-3"
                     placeholder="Search for Medicines Here...." v-model="medi_search" aria-label="Search for Medicines Here...."
                     aria-describedby="search"> -->
                  <span @click="search_for" class="input-group-text bg-transparent border-0 rounded-0 pe-3  col-2" id="search"><i
                        class="icofont-search-1"></i></span>
               </div>
            </a>
         </div>
      </div>
      <!-- body -->
      <div class="my-auto overflow-auto vh-100">
         <div class="bg-light pt-2">
            
            <div class="all-banners">
               <div class="col p-1" v-for="item in banners" :key="item.id">
                       <img :src="getBannerImageUrl(item.image)" alt="image" class="card-img-top rounded-4 mb-2">
                  
               </div>
            </div>
         </div>

         <!-- shop by category -->
         <div class="bg-light pb-3">
            <div class="d-flex align-items-center justify-content-between p-3">
               <h5 class="fw-bold text-black mb-0">Category</h5>
               <a class="text-primary" data-bs-toggle="modal" data-bs-target="#categories" href="#">View all<i
                     class="icofont-rounded-right"></i></a>
            </div>
           
            <div class="all-catex">
               <div class="col ps-3" v-for="item in categoryData" :key="item.id">
                  <a @click="listing(item.id)" class="link-dark">
                     <div class="card bg-transparent border-0 text-center">
                        <img :src="getCatImageUrl(item.img)" alt="image" class="card-img-top rounded-4 mb-2 cat_img">
                        <div class="card-body p-0">
                           <p class="card-title small m-0 cat_title">{{ item.name }}</p>
                        </div>
                     </div>
                  </a>
               </div>
            </div>
         </div>
         <div class="bg-white pb-10">
            <div class="d-flex align-items-center justify-content-between p-3">
               <h5 class="fw-bold text-black mb-0">Comapny</h5> 
            </div>
            <div class="col-12  row p-3"> 
                  <div class="col-3" v-for="item in companyData" :key="item.id">
                     <a @click="cmp_listing(item.id)" class="link-dark">
                        <div class="card bg-transparent border-0 text-center cmp_card">
                           <img :src="getcompanyImageUrl(item.img)" alt="image" class="card-img-top rounded-4 mb-2 cmp_img">
                           <div class="card-body p-0">
                              <p class="card-title small m-0 cmp_title">{{ item.name }}</p>
                           </div>
                        </div>
                     </a>
                   
               </div>
            </div>
         </div>
         
         <!-- Top Picks 
         <h5 class="fw-bold text-black mb-0 px-3 pb-3">Must Have Medicines</h5>
         <ProductItems :productslist="topSaleProduct" :isVerified="isVerified" @addtocart="addtocart"/>
         <h5 class="fw-bold text-black mb-0 px-3 pb-3">Top Picked Medicines</h5>
         <ProductItems :productslist="topPicksProduct" :isVerified="isVerified" @addtocart="addtocart"/>
       -->
         <!-- Categories Modal -->
         <div class="modal fade" id="categories" tabindex="-1" aria-labelledby="categoriesLabel" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered modal-sm modal-dialog-scrollable">
               <div class="modal-content border-0 rounded-4 h-75">
                  <div class="modal-header border-0 px-4">
                     <div class="modal-title" id="categoriesLabel">
                        <h5 class="fw-bold text-black mb-1">Shop by categories</h5>
                        <p class="mb-0">{{categoryData.length}}</p>
                     </div>
                  </div>
                  <div class="modal-body border-top p-4">
                     <div class="row row-cols-3 gy-3">
                        <div class="col" v-for="item in categoryData" :key="item.id">
                           <a @click="listing(item.id)" class="link-dark">
                              <div class="card bg-transparent border-0 text-center" style="    align-items: center; ">
                                 <img :src="getCatImageUrl(item.img)" alt="" class="card-img-top rounded-4 mb-2 cat_img">
                                 <div class="card-body p-0">
                                    <p class="card-title m-0 ">{{ item.name }}</p>
                                 </div>
                              </div>
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
         <!-- banner 
         <div class="p-3 bg-light">
            <div
               class="rounded-4 ps-4 pt-4 shadow-sm d-flex gap-1 align-items-center bg-warning bg-gradient justify-content-between">
               <div class="pb-4">
                  <h1 class="fw-bolder text-black display-5 mb-1">50% OFF</h1>
                  <p class="text-dark">Special Offer: Get 50% Cashback + <span class="text-success"><i
                           class="bi bi-basket"></i> Free Delivery</span>
                     <b class="bg-primary px-1 rounded-1 small text-uppercase fw-bold text-white mt-1 d-inline-block">New
                        Users</b>
                  </p>
                  <router-link to="/listing"
                     class="btn btn-light text-success fw-bold rounded-3 shadow-sm btn-sm border-0">SHOP
                     NOW</router-link>

               </div>
               <img src="#" alt="" class="img-fluid mt-auto osahan-offer-banner">
            </div>
         </div>-->
      </div>
   </div>


   <div v-if="varationshow == 1">
      <div class="offcanvas offcanvas-bottom border-0 h-50 show" tabindex="-1" id="variationoffcanvas"
         aria-labelledby="variationoffcanvasLabel">
         <div class="offcanvas-header bg-primary">
            <div class="input-group bg-white rounded-3 border-0 p-0">
               <h5 class="w-80 p-2">Select Options</h5>
               <a @click="variationoff(cur_item)" class="input-group-text bg-transparent border-0 rounded-0" id="search"
                  data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-circle  fs-5"></i></a>
            </div>
         </div>
         <div class="offcanvas-body p-0">
            <div class="bg-white shadow-sm p-3 border-bottom border-top"  v-for="item in variation" :key="item.id">
               <div class="form-check form-check-reverse">
                  <label class="form-check-label d-flex align-items-center gap-2" for="exampleRadios1">
                     <i class="icofont-cart  icofont-3x text-primary"></i> <span class="var_name">{{item.name}}  (₹ {{item.price}})</span>
                     <div class="text-start">
                  <div
                     class="osahan-count d-flex align-items-center justify-content-between border border-dark-subtle rounded-pill h6 m-0 p-1">
                     <span class="text-muted minus d-flex" @click="decreaseQuantity(item)"><i class="icofont-minus-circle"></i></span>
                     <input type="text" class="lh-sm small text-black text-center box border-0" v-model="item.quantity" >
                     <span class="text-muted plus d-flex" @click="increaseQuantity(item)"><i class="icofont-plus-circle"></i></span>
                  </div>
               </div>
               </label>
               </div>
            </div>
          

            <div class="row g-0">
               <div class="col-12 border-bottom">
                  <h6 class="p-3 m-0">Total Cart Items <span class="fw-bold">"{{cart_cnt}}"</span></h6>
               </div>
            </div>
            <div class="row border-bottom g-0">
               <button class="btn btn-success w-100 text-uppercase btn-lg fw-bold" @click=addvatiation_tocart()>Add To Cart</button>
            </div>

         </div>
      </div>
      <div class="offcanvas-backdrop fade show"></div>
   </div>
   <BottomMenu :cart_cnt="cart_cnt"  :isVerified="isVerified"/>
   <Canvases :categoryData="categoryData" :user="user" />
   <Sidebar :cats="categoryData"/>

   <!-- Bootstrap Bundle Js -->

</template>
<style>
.lgog_img{
   width: 55px;
   height: 55px;
   border-radius: 10px;
}
.swal2-title{
   font-size: larger;
}
.pb-10{
margin-bottom: 100px;
}
.cat_img{
   width: 65px;
    height: 65px;
    border-radius: 5px !important;
    box-shadow: -1px 1px 6px #b5b5b585;
}
.cmp_img{
   width: 70px;
    height: 70px;
    border-radius: 5px !important;
    box-shadow: -1px 1px 6px #b5b5b585;
}
.cat_title {
    font-weight: 500;
    height: 2.3em;
    overflow: hidden;
    line-height: 12px;
}
.cmp_card{
   align-items: center !important;
}
.cmp_title {
   font-size: 12px!important;
    font-weight: 600!important;
}
.phonenumber{
   font-size: 14px !important;
}
</style>
<script>


// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Canvases from '@/components/Canvases.vue'
import BottomMenu from '@/components/BottomMenu.vue'
import ProductItems from '@/components/ProductItems.vue'
import Autocomplete from '@/components/Autocomplete.vue'

import axios from 'axios';
import { mask } from 'vue-the-mask'; 

const config = {
   headers: {
      "Content-Type": "application/x-www-form-urlencoded"
   },
   responseType: 'json'
};
export default {
   name: 'ListleadView',
   components: {
      Canvases, Sidebar,BottomMenu,ProductItems,Autocomplete
   },
   data() {
      return {
         categoryData: [],
         companyData: [],
         topSaleProduct: [],
         varationshow: 0,
         topPicksProduct: [], 
         var_item_array:[],
         cur_item:[],
         isSlickInit:0,
         isVerified:0,
         cart_cnt:0,
         user:[],
         medi_search:'',
         var_qty:0,
         variation:[],
         banners:[],
         product_names_for_search:[]

      }
   },
   mounted() {
      this.loadBasicData();

   }, updated() {
      if(this.isSlickInit==0){
         $('.all-catex').slick({
               infinite: true,
               arrows: false,
               autoplay: true,
               slidesToShow: 8.5,
               slidesToScroll: 5,
               responsive: [{
                  breakpoint: 500,
                  settings: {
                     dots: false,
                     arrows: false,
                     infinite: false,
                     slidesToShow: 5.5,
                     slidesToScroll: 3
                  }
               }]
            });
            $('.all-banners').slick({
            infinite: true,
            arrows: false,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [{
               breakpoint: 500,
               settings: {
                  dots: true,
                  arrows: true,
                  infinite: false,
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            }]
         });
      }
      this.isSlickInit=1;
      
   },
   methods: { 
      handleSelection(value) {
      this.medi_search = value; // Update the selectedValue with the emitted value
    },
      addvatiation_tocart() {
         var self=this;
         axios.post('ajaxAddVariationToCart', {data:this.var_item_array,token:((localStorage['token'])?localStorage['token']:'')}).then(response => {
                                            
            self.cart_cnt= response.data.total_qty; 
            self.varationshow =0;
                                       } ).catch(error => {
                                          console.error(error);
                                       });
      },
      increaseQuantity(item) {
           this.cur_item=item;
           item.quantity++;  
           this.var_item_array[item.id]=item;
      },
      decreaseQuantity(item) {
         if (item.quantity >= 0) {
            this.cur_item=item;
            item.quantity--; 
            this.var_item_array[item.id]=item;
         }
      },
      addtocart(id,varcnt) {
                              var self=this;
         if(!localStorage['token']){
            this.$swal.fire({
                  title: "Please Register To Purchase",
                  showCancelButton: true,
                  confirmButtonText: "Register" 
                  }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                     this.$router.push({ name: "registration", });
                  }  
                  });
            //
         }else{ 
                     if(varcnt>0){
                              axios.post('getAjaxVariation', {prd_id: id,token:((localStorage['token'])?localStorage['token']:'')}).then(response => {
                                 var status = response.data.msg;
                                          if(status.toLowerCase().indexOf("n") != -1 || status.indexOf("Pending") != -1)
                                             self.$swal(status);
                                          else{
                                                         self.variation = response.data.varlist;
                                                         self.varationshow = 1;
                                          }
                                                
                                       } ).catch(error => {
                                          console.error(error);
                                       });
                           }else{
                              axios.post('ajaxAddToCart', {prd_id: id,variation:0,token:((localStorage['token'])?localStorage['token']:'')}).then(response => {
                                            self.cart_cnt= response.data.total_qty; 
                                            var msg= response.data.msg; 
                                            self.$swal(msg);

                                       } ).catch(error => {
                                          console.error(error);
                                       });
                           }
                  
               
            }

        
      },
      variationoff(cur_item) {
         this.varationshow = 0;
         this.var_item_array = this.var_item_array.filter(function( obj ) {
               return obj.food_id !== cur_item.food_id;
               });
          
      },
      async loadBasicData() {
         
         var self = this;
         axios.post('categorylist',{ token: ((localStorage['token']) ? localStorage['token'] : '')}).then(function (response) {
            self.categoryData = response.data.categorylist;
            self.companyData = response.data.companyData;
            self.banners = response.data.banners;
            self.topSaleProduct = response.data.topSaleProduct;
            self.topPicksProduct = response.data.topPicksProduct;
            self.cart_cnt = response.data.cart_cnt;
            self.user = response.data.user;
            self.product_names_for_search=response.data.product_names_for_search;

            if(self.user)
               self.isVerified=self.user.status;
            else
            self.isVerified=0;
         });

      },
      initiate_cat() {
         $('.all-catex').slick({
            infinite: true,
            arrows: false,
            autoplay: true,
            slidesToShow: 2.5,
            slidesToScroll: 3,
            responsive: [{
               breakpoint: 500,
               settings: {
                  dots: false,
                  arrows: false,
                  infinite: false,
                  slidesToShow: 2,
                  slidesToScroll: 2
               }
            }]
         });
         $('.all-banners').slick({
            infinite: true,
            arrows: false,
            autoplay: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            responsive: [{
               breakpoint: 500,
               settings: {
                  dots: false,
                  arrows: false,
                  infinite: false,
                  slidesToShow: 1,
                  slidesToScroll: 1
               }
            }]
         });
      },
      search_for() {
         if(this.medi_search){
            var search_txt=encodeURIComponent(this.medi_search);
            this.$router.push({ name: "listing", query: {id:search_txt } });
         }
            

      },
      listing(id) {
         $("#categories").toggle();
         $(".modal-backdrop").remove();
         $("body").removeClass("modal-open");
         $("body").removeAttr("style");
         this.$router.push({ name: "listing", query: { id } });

      },
      cmp_listing(id) {
         $("#categories").toggle();
         $(".modal-backdrop").remove();
         $("body").removeClass("modal-open");
         $("body").removeAttr("style");
         this.$router.push({ name: "listing_cmp", query: { id } });

      },
      saaveincard() {
         this.$router.push({ name: "cart", });
      },
      getCatImageUrl(img) {
         return `${this.$baseurl}public/uploads/category/${img}`;
      },
      getcompanyImageUrl(img) {
         return `${this.$baseurl}public/uploads/company/${img}`;
      },
      getBannerImageUrl(img) {
         return `${this.$baseurl}public/uploads/banner/${img}`;
      },
      getProductImageUrl(img) {
         return `${this.$baseurl}public/uploads/product/${img}`;
      }
      , product_details(id) {
         var self = this;
         self.$router.push({ name: "product_details", query: { id } });
      },
      ask_login(){
         var self=this;
         if(!localStorage['token']){
            this.$swal.fire({
                  title: "Please Register / Login To Access",
                  showCancelButton: true,
                  confirmButtonText: "Register" 
                  }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                     this.$router.push({ name: "registration", });
                  }  
                  });
               }
      }


   }
}
</script>
