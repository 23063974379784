   <template>
        <div class="listing-navbar bg-primary d-flex align-items-center gap-3 shadow-sm mb-auto p-3">
         <a @click="$router.go(-1)"><i class="bi bi-arrow-left fs-5 text-white"></i></a>
            <a href="#" class="text-white" data-bs-toggle="modal" data-bs-target="#categories">
               <div class="d-flex align-items-center gap-2">
                  <img src="img/delivery-boy.jpg" alt="" class="rounded-pill img-fluid">
                  <div>
                     <h6 class="fw-bold text-white mb-0">{{user.name}} </h6>
                     <p class="text-white-50 small m-0">{{user.number}} </p>
                  </div>
               </div>
            </a>
            
         </div>
         <!-- body -->
         <div class="vh-100 my-auto overflow-auto p-3">
            
            <h5 class="fw-bold text-black mb-3">Past orders</h5>
            <div class="bg-white rounded-3 shadow-sm mb-3 border"  v-for="item in orders" :key="item.in">
               <div class="d-flex align-items-start justify-content-between border-bottom p-3">
                  <div>
                     <h6 class="fw-bold text-black osahan-mb-1">Order No {{ item.id }}</h6>
                     <p class="text-muted small mb-2"></p>
                     <a href="#" class="text-muted">₹ {{ item.amount }}<i class="icofont-rounded-right ms-1"></i></a>
                  </div>
                  <p class="bg-success bg-opacity-10 text-success rounded small px-2 py-1">{{ (item.order_status==0)?'New Order':'Processing' }}<i class="icofont-check-circled ms-1"></i></p>
               </div>
               <div class="p-3">
                  <p class="mb-1 multiline">{{ item.order_details?  item.order_details.replace(/ *, */g, '\n'):'' }}
                  </p>
                  <p class="text-muted small text-info m-0"><i class="icofont-clock-time"></i> {{ item.iddate }}</p>
               </div>
            </div> 
            
         </div> 
     
   <BottomMenu :cart_cnt="cart_cnt" :isVerified="isVerified"/>
     
</template>
<style scoped>
.border{
   border: 1px solid !important;
  
}
.multiline{
   white-space: pre-line;
}
</style>
<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Canvases from '@/components/Canvases.vue'
import BottomMenu from '@/components/BottomMenu.vue'
import Header from '@/components/Header.vue'
import axios from 'axios';

export default {
   name: 'orders',
   components: {
      Sidebar,
      Canvases,
      Header,BottomMenu
   },
   data() {
      return {
         user: [],
         orders: [],
         var_count:0,
         cart_cnt:0,
         isVerified:0
      };
   },
   mounted() {
      this.loadBasicData();

   },
   methods: {
     
      loadBasicData(id) { 
         var self = this; 
         axios.post('profile',{token:((localStorage['token'])?localStorage['token']:'')}).then(function (response) { 
            self.user = response.data.user; 
            self.orders = response.data.orders; 
            self.isVerified = 1; 
         });

      } ,
      logout(){
      localStorage.removeItem('token');
      this.$router.push({ name: "/"});
   }
      
    
   }
};
</script>