<template>

   <div class="product-details d-flex flex-column vh-100">
      <!-- navbar -->
      <div class="d-flex align-items-center gap-3 p-3 bg-primary">
         <a @click="$router.back()"><i class="bi bi-arrow-left fs-5 text-white"></i></a>
         <div>
            <h6 class="fw-bold text-white mb-0">{{ medicinename }}</h6> 
         </div>
         <div class="d-flex align-items-center gap-2 ms-auto">
               <router-link  :to="(isVerified)?'/profile':'/'" class="link-dark">
                  <div class="bg-dark bg-opacity-75 rounded-circle user-icon"><i
                        class="bi bi-person d-flex m-0 h4 text-white"></i></div>
               </router-link>
               
            </div>
      </div>
      <!-- slider -->
      <div class="vh-100 my-auto overflow-auto">
         <div class="bg-white shadow-sm p-3 mb-3">
            <div class="product-slider-here">
               <div class="product-item " style="place-self: center;" v-for="item in imageArray" :key="item">
                                 <a
                        :href="getProductImageUrl(item)"
                        data-fancybox="gallery"
                        data-caption="Healthline Distributors"
                     >
                  <img :src="getProductImageUrl(item)" alt="" class="img-fluid mx-auto square_size">
                     </a>
               </div>

            </div>
            <!-- detail -->
            <div class="pt-4">
               <p class="text-primary mb-1 cmp_name_txt">{{ company_name }}</p>
               <h5 class="fw-bold text-black lh-base">{{ medicinename }}
               </h5>
                  <div class="d-flex align-items-center  ">
                     <h5>{{rating}}</h5>&nbsp;&nbsp;<div class="bg-white  "  v-for="item in rating" :key="item">⭐</div> 
                  </div>  
            </div>
            <div class="d-flex align-items-center justify-content-between">
               <div class="mb-0">
               <p class="mb-0">MRP : ₹{{ (mrp) ? mrp : 'NA' }}</p>
               </div>
               <div class="mb-0">
                  <p class="mb-0">₹{{ (isVerified) ? price : 'Verification Required' }}</p>
               </div>
               <p class="text-muted mb-1">{{ (scheme)?'Scheme: '+scheme:'' }}</p>  
               <div>
               </div>
            </div>
         </div>
            
         <!-- important information -->
         <div class="bg-white shadow-sm p-3">
            <p class="text-primary mb-1">Salt</p>
            <p class="text-muted m-0 salt">{{ discription }}</p>
         </div>
         <div class="bg-white shadow-sm p-3" v-if="server_variation.length>0">
            <p class="text-primary mb-1">Variation</p> 
            <div class="d-flex align-items-center  ">
               <div class="bg-white  m-1 "  v-for="item in server_variation" :key="item.id">
                     <div :class="selected_variation_id==item.id?'varcard_selected':'varcard'" @click="select_variation(item.id)">
                        <p>{{ item.name }}</p>
                        <p>₹.{{ item.mrp }}</p>
                     </div>
                  </div>
               </div>
         </div>
         <div class="card card-body p-4">
            <p>{{ spcl_discount?'Special Offer : '+spcl_discount:'' }}</p>
            <!-- {{'Special Offer : '+spcl_discount }} -->
         </div>
         
         <div class="d-flex align-items-center justify-content-between p-3" v-if="showAddTocard">
                  <div class="mb-0">
                     <input placeholder="Enter Qty Here" value="1" v-model="prd_qty" min=1 class="qttybox" type="number"></input>
                  </div>  
                  <div class="mb-0">
                     <button class="btn btn-success w-100  text-uppercase   fw-bold" @click="addtocart(foodid)">ADD TO CART</button>
                  </div>
              
               <div>
               </div>
            </div>
            <div class="input-group p-3">
               <div class="row">
                
               </div>
            </div>
      </div>  
      <div class="mt-auto bg-white p-3">
        
      </div>
   </div>


   <div v-if="varationshow == 1">
      <div class="offcanvas offcanvas-bottom border-0 h-50 show" tabindex="-1" id="variationoffcanvas"
         aria-labelledby="variationoffcanvasLabel">
         <div class="offcanvas-header bg-primary">
            <div class="input-group bg-white rounded-3 border-0 p-0">
               <h5 class="w-80 p-2">Select Options</h5>
               <a @click="variationoff(cur_item)" class="input-group-text bg-transparent border-0 rounded-0" id="search"
                  data-bs-dismiss="offcanvas" aria-label="Close"><i class="bi bi-x-circle  fs-5"></i></a>
            </div>
         </div>
         <div class="offcanvas-body p-0">
            <div class="bg-white shadow-sm p-3 border-bottom border-top" v-for="item in variation" :key="item.id">
               <div class="form-check form-check-reverse">
                  <label class="form-check-label d-flex align-items-center gap-2" for="exampleRadios1">
                     <i class="icofont-cart  icofont-3x text-primary"></i> <span class="var_name">{{ item.name }} (₹.{{
                        item.price }})</span>
                     <div class="text-start">
                        <div
                           class="osahan-count d-flex align-items-center justify-content-between border border-dark-subtle rounded-pill h6 m-0 p-1">
                           <span class="text-muted minus d-flex" @click="decreaseQuantity(item)"><i
                                 class="icofont-minus-circle"></i></span>
                           <input type="text" class="lh-sm small text-black text-center box border-0"
                              v-model="item.quantity">
                           <span class="text-muted plus d-flex" @click="increaseQuantity(item)"><i
                                 class="icofont-plus-circle"></i></span>
                        </div>
                     </div>
                  </label>
               </div>
            </div>


            <div class="row g-0">
               <div class="col-12 border-bottom">
                  <h6 class="p-3 m-0">Total Cart Items <span class="fw-bold">"{{ cart_count }}"</span></h6>
               </div>
            </div>
            <div class="row border-bottom g-0">
               <button class="btn btn-success w-100 text-uppercase btn-lg fw-bold" @click=addvatiation_tocart()>Add To
                  Cart</button>
            </div>

         </div>
      </div>
      <div class="offcanvas-backdrop fade show"></div>
   </div>
   <BottomMenu :cart_cnt="cart_cnt"  :isVerified="isVerified"/>
   <Canvases :varationshow="varationshow" />
</template>
<style>
.cmp_name_txt{
   font-size: larger;
}
.text-muted{
   font-size: medium;
}
.qttybox{
   padding: 2px;
    border: 1px solid black;
    border-radius: 10px;
    text-align: center;
}
.varcard{border: 1px solid gray;
    border-radius: 10px;
    padding: 5px !important;
}
.varcard_selected{border: 1px solid red;
    border-radius: 10px;
    padding: 5px !important;
    background: yellow;
}
p {
    font-size: 18px;
}
.text-muted {
    color: #000000 !important;
    font-size: medium !important;
}
.salt {
   white-space: break-spaces;
}

</style>
<style scoped>
.square_size{
   height: 250px;
   width: auto;
}
</style>

<script>
// @ is an alias to /src
import Sidebar from '@/components/Sidebar.vue'
import Canvases from '@/components/Canvases.vue'
import Header from '@/components/Header.vue'
import BottomMenu from '@/components/BottomMenu.vue'
import axios from 'axios';
import '@fancyapps/ui/dist/fancybox/fancybox.css';

export default {
   name: 'product_details',
   components: {
      Sidebar,
      Canvases,
      Header,BottomMenu
   },
   data() {
      return {
         foodid: 0,
         company_name:'',
         showAddTocard:false,
         medicinename: '',
         price: '',
         qty: '',
         description: '',
         catname: '',
         varationshow: 0,
         variation: [],
         server_variation: [],
         cart_exist: [],
         imageArray: [],
         selected_var: [],
         selected_qty: [],
         var_count: 0,
         cart_count: 0,
         selected_variation_id: 0,
         var_item_array: [],
         cur_item: [],
         isVerified:0,
         prd_qty:1,
         rating:0,
         spcl_discount:0,
         catimg:''
      };
   },
   
   created() {
      if (this.$route.query.id > 0) {
         this.singledata(this.$route.query.id);
       }
      },
   methods: { 
   
      select_variation(id){
         var self=this;
         this.selected_variation_id=id; 
         if(this.cart_exist.length>0){
               var exists = this.cart_exist.some(function(field) {
               return field.variation === self.selected_variation_id
            });
            if(exists)
               this.showAddTocard=false;
            else
            this.showAddTocard=true;
         }else
         this.showAddTocard=true;
         
      },
      addvatiation_tocart() {
         var self=this;
         axios.post('ajaxAddVariationToCart', { data: this.var_item_array,prd_qty:this.prd_qty, token: ((localStorage['token']) ? localStorage['token'] : '') }).then(response => {
            var status = response.data.msg;
              self.$swal(status);
            
            self.cart_cnt = response.data.total_qty;
            self.varationshow = 0;

         }).catch(error => {
            console.error(error);
         });
      },
      increaseQuantity(item) {
         this.var_item_array[item.id] = item;
         this.cur_item = item;
         item.quantity++;
      },
      decreaseQuantity(item) {
         if (item.quantity >= 0) {
            this.var_item_array[item.id] = item;
            this.cur_item = item;
            item.quantity--;
         }
      },
      addtocart(id) {
         self=this;
         if (!localStorage['token'] || localStorage['token']=='undefined') {
            this.$swal.fire({
               title: "Please Register/Wait For Verification To Purchase",
               showCancelButton: true,
               confirmButtonText: "Register"
            }).then((result) => {
               /* Read more about isConfirmed, isDenied below */
               if (result.isConfirmed) {
                  this.$router.push({ name: "registration", });
               }
            });
            //
         } else {
            if (this.var_count >0 && this.selected_variation_id==0) {

               //if (this.var_count >0)  changed after request for show variation like amazon on product page . before bottom popup is there
               axios.post('getAjaxVariation', { prd_id: id , token: ((localStorage['token']) ? localStorage['token'] : '') }).then(response => {
                  var status = response.data.msg;
                                          if(status.toLowerCase().indexOf("n") != -1 || status.indexOf("Pending") != -1)
                                             self.$swal(status);
                                          else{
                                             self.variation = response.data.varlist;
                                             self.varationshow = 1;
                                          }
                                          
                                          

               }).catch(error => {
                  console.error(error);
               });
            } else {
               axios.post('ajaxAddToCart', { prd_id: id,prd_qty:this.prd_qty ,variation: this.selected_variation_id, token: ((localStorage['token']) ? localStorage['token'] : '') }).then(response => {
                  self.cart_cnt = response.data.total_qty;
                  var msg = response.data.msg;
                  
                  

               self.cart_exist = response.data.cart_exist;
               if(self.cart_exist.length>0)
                  self.showAddTocard=false;

               if(self.select_variation>0)
                  self.select_variation(select_variation);


                  self.$swal(msg);

               }).catch(error => {
                  console.error(error);
               });
            }
         }
      },
      variationoff() {
         this.varationshow = 0;
      },
      singledata(id) {
         var self=this;
         axios.post('getproduct_data', {
            id: id,
            token: ((localStorage['token']) ? localStorage['token'] : '') 
         }).then(response => {
            self.cart_exist = response.data.cart_exist;
            if(self.cart_exist.length>0)
               self.showAddTocard=false;
            else
            self.showAddTocard=true;


            const data = response.data.singledatalist; // Adjust this line as per your API response structure
            self.medicinename = data.foodname;
            self.company_name = data.company_name;
            self.price = data.price;
            self.mrp = data.mrp;
            self.spcl_discount = data.spcl_discount;
            self.description = data.description;
            self.qty = data.quantity;
            self.img = data.image;
            self.discription = data.discription;
            self.catname = data.catname;
            self.scheme = data.scheme;
            self.foodid = data.id;
               self.rating = (data.rating)?parseInt(data.rating):0;
            self.var_count = data.var_count;
            self.isVerified=response.data.isVerified;
            self.server_variation=response.data.variation;
            self.cart_cnt = response.data.cart_cnt;



            if (data.image)
            self.imageArray.push(data.image);
            if (data.img_2)
            self.imageArray.push(data.img_2);
            if (data.img_3)
            self.imageArray.push(data.img_3);
            if (data.img_4)
            self.imageArray.push(data.img_4);

            if(self.imageArray.length==0){
               self.imageArray.push('');
            }
            self.catimg = response.data.cat.img;

           
         }).then(() => {
            $('.product-slider-here').slick({
               infinite: true,
               arrows: true,
               autoplay: true,
               dots: true,
               slidesToShow: 1,
               slidesToScroll: 1,
               slickLightbox: true
            });


            import('@fancyapps/ui').then(({ Fancybox }) => {
                  Fancybox.bind('[data-fancybox="gallery"]', {
                  infinite: false, // Customize as needed
                  Toolbar: true,
                  });
               });
               
         }).catch(error => {
            console.error(error);
         });
      },
      getProductImageUrl(img) {
            if(img && img.length>0)
               return `${this.$baseurl}public/uploads/product/${img}`;
            else
               return `${this.$baseurl}public/uploads/category/${(this.catimg)?this.catimg:'temp.png'}`;
               //return `${this.$baseurl}public/uploads/product/temp.png}`;
         } ,
   }
};
</script>

<style>
.var_name {
   width: 50%;
   text-align: center;
}

.w-80 {
   width: 80%;
}

.w-50 {
   width: 50%;
}

.osahan-count {
   font-size: x-large;
}
</style>
