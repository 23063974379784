<template>
     <div class="py-3">
           
            <div class=" ">
               <div class="row border-bottom g-0 product_items">
                  <div class="col-6 " v-for="item in productslist" :key="item.id">
                      <a @click="product_details(item.id)" class="link-dark"> 
                        <div class="card bg-transparent border-0 p-2 h-100">
                         
                           

                           <img :src="getProductImageUrl(item.image)" alt="" class="img-fluid w-75 d-block mx-auto square_size">

                           <div class="card-body p-0 mb-1">
                              <p class="card-title fw-bold mb-0">{{ item.foodname }}</p>
                              <small class="text-muted">{{ item.scheme }}</small>
                           </div>
                           <div
                              class="card-footer bg-transparent border-0 d-flex align-items-end justify-content-between p-0">
                              <div> 
                                 <span  class="text-muted small">MRP :</span> <del class="text-muted small">₹ {{ item.mrp }}</del>
                                 <p class="fw-bold m-0">₹ {{ (isVerified)?item.price:'Verification Required' }}</p>
                            
                              </div>
                              <!-- <button type="button" @click="addtocart(item.id,item.var_count)"
                                 class="btn btn-outline-success fw-bold rounded-3 shadow-sm btn-sm btn_ywl">ADD</button> -->
                           </div>
                        </div>
                    
                        </a>
                  </div>

               </div>

            </div>
         </div>

         </template>

<script>
import axios from 'axios';
export default {
  name: 'ProductItems' ,
      props:
      {
        productslist:Array,isVerified:Number,catimg:String

      },data() {
            productslist:this.productslist;
      },
    
    methods: { 
        addtocart(id,varcnt) {
         this.$emit('addtocart', id,varcnt); 
        }, 
        getProductImageUrl(img) {
            if(img)
               return `${this.$baseurl}public/uploads/product/${img}`;
            else
               return `${this.$baseurl}public/uploads/category/${(this.catimg)?this.catimg:'temp.png'}`;
         }
,product_details(id) {
         var self = this;
         self.$router.push({ name: "product_details", query: { id } });
      }


    }
 
}
</script>
