<template>
    <keep-alive>
     <router-view ></router-view>
   </keep-alive>
</template>

 <style>
 .product_items .col-6{
     border: 1px solid #e9e8e8; 
 }
 .btn_ywl{
    background: yellow !important;
    color: black !important;
    border: 1px solid black !important;
 }
 .vh-100 {
    height: 100vh !important;
}
 
.square_size{
   height: 120px;
   width: auto;
} 
</style>
