<template>

   <div class="cart d-flex flex-column vh-100">
      <!-- navbar -->
      <div class="bg-white mb-auto">
         <div class="d-flex align-items-center gap-3 p-3 bg-primary">
            <a @click="$router.go(-1)" class="text-white"><i class="bi bi-arrow-left fs-5"></i></a>
            <div>
               <h6 class="fw-bold text-white mb-0">Review Cart</h6>
               <p class="text-white-50 small m-0">Healthline Distributers</p>
            </div>
            <div class="d-flex align-items-center gap-2 ms-auto">
               <router-link :to="(isVerified)?'/profile':'/'" class="link-dark">
                  <div class="bg-dark bg-opacity-75 rounded-circle user-icon"><i
                        class="bi bi-person d-flex m-0 h4 text-white"></i></div>
               </router-link>
               <a class="toggle" href="#">
                  <b class="bg-dark bg-opacity-75 rounded-circle user-icon">
                     <i class="bi bi-list d-flex m-0 h4 text-white"></i>
                  </b>
               </a>
            </div>
         </div>

      </div>
      <!-- body -->
      <div class="my-auto vh-100 overflow-auto p-3">
         <!-- coupon 
            <a href="#" class="link-dark" data-bs-toggle="offcanvas" data-bs-target="#applycoupon"
               aria-controls="applycoupon">
               <div class="bg-white d-flex align-items-center gap-3 p-3 mb-2 shadow-sm rounded-3">
                  <i class="icofont-sale-discount icofont-2x"></i>
                  <div>
                     <h6 class="fw-bold text-black osahan-mb-1">Apply Coupons</h6>
                     <p class="small text-primary m-0">Save more with coupons available for you</p>
                  </div>
                  <div class="ms-auto"><i class="bi bi-chevron-right fs-5"></i></div>
               </div>
            </a>-->
         <!-- review items -->
         <div class="bg-white p-3 mb-2 shadow-sm rounded-3">
            <div class="d-flex align-items-center gap-3 mb-3">
               <i class="icofont-food-basket icofont-2x"></i>
               <div>
                  <h6 class="fw-bold text-black osahan-mb-1">Review Items</h6>
                  <p class="small text-muted m-0">{{ cartitems.length }} item</p>
               </div>
            </div>
            <div class="cart_item" v-for="item in cartitems"
               :key="item.id">
               
               <div class="me-auto">
                  <p class="osahan-mb-1">{{ item.foodname }}</p>
                  <div class="text-muted">{{ item.scheme }}</div>
                  <p class="text-muted small m-0">MRP. ₹.{{ item.mrp }} &nbsp;&nbsp;&nbsp; <span class="text-success ">Sale Rate ₹.{{ item.price }}</span></p>
               </div>
               <div class="d-flex align-items-center gap-3 justify-content-between">
                     <div
                        class="osahan-count d-flex align-items-center justify-content-between border border-dark-subtle rounded-pill h6 m-0 p-1">
                        <span class="text-muted minus d-flex" @click="decreaseQuantity(item)"><i
                              class="icofont-minus-circle"></i></span>
                        <input type="text" class="lh-sm small text-black text-center box border-0 cart_qty" v-model="item.quantity"  >
                        <span class="text-muted plus d-flex" @click="increaseQuantity(item)"><i
                              class="icofont-plus-circle"></i></span>
                     </div>
                     <p class="text-muted small m-0">{{ item.var_name }} </p>
                     <span class="m-0 text-primary total_amt cart_rate" style="    white-space: nowrap;">₹ {{ (parseFloat(item.quantity) * parseFloat(item.price)).toFixed(2) }}</span>
                     <span style="display:none;" class="mrp_total ">₹ {{ (parseFloat(item.quantity) * parseFloat(item.mrp)).toFixed(2) }}</span>
                     <span @click="remove_from_cart(item.id)"><i class="icofont-trash"></i></span>
            </div>
            </div>
         </div>
        
         <!-- bill -->
         <div class="bg-white p-3 mb-2 shadow-sm rounded-3">
            <h6 class="fw-bold text-black mb-3">Bill Details</h6>
            <div class="border-bottom">
               <div class="d-flex align-items-center justify-content-between mb-2">
                  <p class="m-0">MRP Total</p>
                  <p type="hidden" class="m-0">₹ {{ mrp_total }}</p>
               </div>

            </div>
            <div class="d-flex align-items-center justify-content-between pt-3">
               <p class=" m-0">Taxable Amount</p>
               <h6 class="fw-bold  m-0">₹ {{ parseFloat(cart_total).toFixed(2)}}</h6>
            </div>
            <div class="d-flex align-items-center justify-content-between pt-3">
               <p class=" m-0">GST Amount</p>
               <h6 class="fw-bold  m-0">₹ {{ parseFloat(gst_total).toFixed(2) }}</h6>
            </div>
            <div v-if="finalDisc>0" class="d-flex align-items-center justify-content-between pt-3">
               <p class="fw-bold m-0 text-success">Discount</p>
               <h6 class="fw-bold  m-0 text-success"> {{ parseFloat(finalDisc).toFixed(2) }} %</h6>
            </div>
            <div class="d-flex align-items-center justify-content-between pt-3">
               <h6 class="text-primary m-0">Final Amount</h6>
               <h6 class="fw-bold text-primary m-0">₹ {{ (parseFloat(cart_total)+parseFloat(gst_total)).toFixed(2) }}</h6>
            </div>
         </div>

      </div>
      <!-- footer -->
      <div class="mt-auto shadow-sm border-top">
         <div class="bg-light d-flex align-items-center border-bottom justify-content-between px-3 py-2">
            <p class="m-0">Subtotal: ₹.{{ (parseFloat(cart_total)+parseFloat(gst_total)).toFixed(2) }}</p>
            <div>
               <label @click="setDisc(1)"><input type="radio" name="disc"/> Cash {{ (cash_disc>0)?cash_disc+'% OFF':'' }}</label> &nbsp;&nbsp;&nbsp;
               <label @click="setDisc(2)"><input type="radio" name="disc"/> Credit {{ (cred_disc>0)?cred_disc+'% OFF':'' }}</label>
            </div>

         </div>
         <div class="bg-white p-3">

            <div class="pt-3">
               <button  @click="confirmOrder" class="btn btn-success w-100 text-uppercase btn-lg fw-bold">Confirm Order</button>
            </div>
         </div>
      </div>
   </div>

</template>
<style scoped>
.icofont-plus-circle{
   font-size: larger;
}
.cart_qty{
   font-size: medium;
}
.icofont-minus-circle{
   font-size: larger;
}


.icofont-trash{
}
.cart_item{
   border: 1px solid #80808066;
    padding: 5px;
    border-radius: 10px;
    margin-top: 5px;
}
.cart_rate{
   font-size: medium;
}
</style>
<script>
import cart from '@/views/cart.vue'
import Sidebar from '@/components/Sidebar.vue'
import Canvases from '@/components/Canvases.vue'
import Header from '@/components/Header.vue'
import axios from 'axios';
import { mask } from 'vue-the-mask'

const config = {
   headers: {
      "Content-Type": "application/x-www-form-urlencoded"
   },
   responseType: 'json'
};
export default {
   name: 'cart',
   props:
   {
      // SelectedItem:Array,         
   },
   components: {
      Header
   },
   data() {
      return {
         cartitems: [],
         id: '',
         qty: '',
         cart_total: '0',
         mrp_total: '0',
         totalitem: '0',
         subTotalAmount: 0,
         isVerified:0,
         cash_disc:0,
         cred_disc:0,
         gst_total:0,
         finalDisc:0,
         billType:0
      }
   },

   mounted() {
      this.getCartData();
   }, 
   methods: {
      setDisc(what){
         this.billType=what;
         if(what==1)
            this.finalDisc=this.cash_disc;
         else if(what==2)
          this.finalDisc=this.cred_disc;

          this.totalBill();
      },
      getImageUrlfood(img) {
         return `${this.$baseurl}public/uploads/product/${img}`;
      }
      ,
cart_update(item,what){
   var self=this;
   axios.post('updateCartData',{token:((localStorage['token'])?localStorage['token']:''),item:item,what:what}).then(function (response) { 
            self.cartitems = response.data.cartitems;
            self.cart_total = response.data.cart_total;
            self.subTotalAmount = response.data.subTotalAmount;
            self.isVerified=response.data.isVerified;
             self.cash_disc=response.data.cash_disc;
             self.cred_disc=response.data.cred_disc;
             self.mrp_total=response.data.mrp_total;
             self.gst_total=response.data.gst_total;
         });
        // this.totalBill();
},
      increaseQuantity(item) {
         item.quantity++;
         this.cart_update(item,'+');
         //this.totalBill();
      },
      decreaseQuantity(item) {
         if (item.quantity > 1) {
            item.quantity--;
            this.cart_update(item,'-');
          }
         //this.totalBill();
      },
      totalprice(item) {
         var a = item.foodprice * item.quantity; 
         return a; 
      },
      totalBill() {
         var total_here=0;
         var mrp_total_here=0;
         var gst_total_here=0;
         var discount_here=0;
         var product_final_rate=0;
         // if(this.finalDisc>0){
         //    discount_here=(parseFloat(this.cart_total)-((parseFloat(this.cart_total)*(parseFloat(this.finalDisc)/100)))).toFixed(2);
         //    this.cart_total=discount_here;
         // }


         for(var i=0;i<this.cartitems.length;i++){
            if(this.finalDisc>0){
               discount_here=((parseFloat(this.cartitems[i].price)*(parseFloat(this.finalDisc)/100))).toFixed(3);
               product_final_rate=parseFloat(this.cartitems[i].price)-parseFloat(discount_here);
            }
            else
               product_final_rate=parseFloat(this.cartitems[i].price);//-parseFloat(discount_here);


               total_here+=parseFloat(this.cartitems[i].quantity)*product_final_rate;
               mrp_total_here+=parseFloat(this.cartitems[i].quantity)*parseFloat(this.cartitems[i].mrp);
               if(this.cartitems[i].gst && parseFloat(this.cartitems[i].gst)>0){
                  var final_with_discount=((parseFloat(this.cartitems[i].quantity)*product_final_rate))*(parseFloat(this.cartitems[i].gst)/100);
                  gst_total_here+=final_with_discount;
               }
         }
         // for(var i=0;i<this.cartitems.length;i++){
         //       var product_final_rate=parseFloat(this.cartitems[i].price)-parseFloat(discount_here);


         //       total_here+=parseFloat(this.cartitems[i].quantity)*parseFloat(this.cartitems[i].price);
         //       mrp_total_here+=parseFloat(this.cartitems[i].quantity)*parseFloat(this.cartitems[i].mrp);
         //       if(this.cartitems[i].gst && parseFloat(this.cartitems[i].gst)>0){
         //          var final_with_discount=((parseFloat(this.cartitems[i].quantity)*parseFloat(this.cartitems[i].price)-discount_here))*(parseFloat(this.cartitems[i].gst)/100);
         //          gst_total_here+=final_with_discount;
         //       }
         // }

/*
         $(".total_amt").each(function() {
            total_here+=parseFloat($(this).html().replace('₹','').trim());
         });
         $(".mrp_total").each(function() {
            mrp_total_here+=parseFloat($(this).html().replace('₹','').trim());
         });*/
         this.gst_total=gst_total_here.toFixed(2);
         this.cart_total=total_here.toFixed(2);
         this.subTotalAmount=mrp_total_here.toFixed(2);
        
            

            

      },
      confirmOrder() {

         var self = this;
         if(self.isVerified){
               axios.post('ajaxConfirmOrder',{token:((localStorage['token'])?localStorage['token']:''),
                  confirm:'Yes',
                  billType:self.billType
               }).then(function (response) {
                  var status = response.data.status;
                  var orderid = response.data.orderid;
                  //
                  if(status){
                     if(status.toLowerCase().indexOf("confirmed") != -1)
                        self.$router.push({ name: "order_received", query: { orderid:orderid } });
                     else
                        self.$swal(status);
                  }
               });
            
         }else{
            this.$swal.fire({
                  title: "Please Register / Login To Order",
                  showCancelButton: true,
                  confirmButtonText: "Register" 
                  }).then((result) => {
                  /* Read more about isConfirmed, isDenied below */
                  if (result.isConfirmed) {
                     this.$router.push({ name: "registration", });
                  }  
                  });
         }

      },
      remove_from_cart(id) {

         var self = this;


         axios.post('remove_from_cart',{token:((localStorage['token'])?localStorage['token']:''),id:id}).then(function (response) { 
            self.cartitems = response.data.cartitems;
            self.cart_total = response.data.cart_total;
            self.subTotalAmount = response.data.subTotalAmount;
            self.isVerified=response.data.isVerified;
             self.cash_disc=response.data.cash_disc;
             self.cred_disc=response.data.cred_disc;
             self.mrp_total=response.data.mrp_total;
             self.gst_total=response.data.gst_total;
         });

 

      },
      getCartData() {

         var self = this;


         axios.post('getCartData',{token:((localStorage['token'])?localStorage['token']:'')}).then(function (response) { 
            self.cartitems = response.data.cartitems;
            self.cart_total = response.data.cart_total;
            self.subTotalAmount = response.data.subTotalAmount;
            self.isVerified=response.data.isVerified;
             self.cash_disc=response.data.cash_disc;
             self.cred_disc=response.data.cred_disc;
             self.mrp_total=response.data.mrp_total;
             self.gst_total=response.data.gst_total;
         });

 

      },
   }
}
</script>
