<template>
    <div style="position: relative;">
      <input 
        type="text" 
        v-model="query" 
        @input="filterSuggestions" 
        placeholder="Search..." 
        style="width: 100%; padding: 5px;font-size: 18px;border: 0;"
      />
      <div v-if="filteredSuggestions.length > 0" class="autocomplete-list">
        <div 
          v-for="suggestion in filteredSuggestions" 
          :key="suggestion" 
          class="autocomplete-item" 
          @click="selectSuggestion(suggestion)"
        >
          {{ suggestion }}
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref } from 'vue';
  
  export default {
    props: ['suggestions'],
    setup(props, { emit }) {
      const query = ref('');
      const filteredSuggestions = ref([]);
  
      const filterSuggestions = () => {
        if (query.value) {
            emit('update:selected', query.value); 
          filteredSuggestions.value = props.suggestions.filter(suggestion =>
            suggestion.toLowerCase().includes(query.value.toLowerCase())
          );
        } else {
          filteredSuggestions.value = [];
        }
      };
       
      const selectSuggestion = (suggestion) => {
        query.value = suggestion;
        filteredSuggestions.value = [];
        emit('update:selected', suggestion); 
      };
  
      return {
        query,
        filteredSuggestions,
        filterSuggestions,
        selectSuggestion,
      };
    },
  };
  </script>
  
  <style scoped>
  .autocomplete-list {
      border: 1px solid #ccc;
      max-height: 150px;
      overflow-y: auto;
      position: absolute;
      background: white;
      z-index: 1000;
      font-size: 18px;
      color: black;
      width: 300px;
  }
  .autocomplete-item {
      padding: 10px;
      color: black;
      font-size: 18px;
      cursor: pointer;
  }
  .autocomplete-item:hover {
      background-color: #f0f0f0;
  }
  </style>
  