<template> 
  <div class="footer bg-white shadow mt-auto border-top">
      <div class="d-flex align-items-center justify-content-between py-1">
         <router-link to="/home" class="link-dark text-center col py-2 p-1">
            <i class="bi bi-house h3 m-0"></i>
            <p class="small m-0 pt-1">Home</p>
         </router-link>
         <a data-bs-toggle="modal" data-bs-target="#categories" href="#" class="link-dark text-center col py-2 p-1">
            <i class="bi bi-shop h3 m-0"></i>
            <p class="small m-0 pt-1">Shop</p>
         </a>
         <router-link :to="(isVerified)?'/orders':'/'" class="text-muted text-center col py-2 p-1">
            <i class="bi bi-box-seam h3 m-0"></i>
            <p class="small m-0 pt-1">Orders</p>
         </router-link>
         <router-link :to="(isVerified)?'/cart':'/'"  class="text-muted text-center col py-2 p-1">
            <span :class="`${cart_cnt?'cart_cnt':''}`">{{ (cart_cnt)?cart_cnt:'' }}</span>
            <i class="bi bi-basket h3 m-0"></i>
            <p class="small m-0 pt-1">Cart</p>
         </router-link>
         <router-link :to="(isVerified)?'/profile':'/'"   class="text-muted text-center col py-2 p-1">
            <i class="bi bi-person h3 m-0"></i>
            <p class="small m-0 pt-1">Profile</p>
         </router-link>
      </div>
   </div>
</template>
<style>
.cart_cnt{
   position: absolute;
    z-index: 9;
    color: white;
    background: red;
    margin-left: 15px;
    padding: 0px;
    width: 20px;
    border-radius: 8px;
    height: 20px;

}
</style>
<script>
export default {
  name: 'BottomMenu',
  props:
      {
         cart_cnt:Number,
         isVerified:Number
      }
}
</script>
